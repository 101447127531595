/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, ButtonGroup, Button, Modal, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import Gallery from 'react-grid-gallery';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import { addStickerThunk, updateSKUThunk, updateStickerThunk } from '../reducers/actions';
const DEFAULT_VIDEO_URI ='https://firebasestorage.googleapis.com/v0/b/the-verify-app.appspot.com/o/Videos%2FtheVerifyApp.mp4?alt=media&token=a6cff87c-ad91-4669-8e6a-033c5e0ea62c';
class SKU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [],
      addStickerModal: false,
      updateSKUModal: false,
      isStickerUpdate: false,
      chipId: '',
      expiryDate: '',
      manufactureDate: '',
      manufactureLocation: '',
      videoURI: DEFAULT_VIDEO_URI,
      name: '',
      description: '',
      code: '',
      type: '',
      pictureURI: '',
      bannerAdURI: '',
      videoAd: {
        exists: false,
        informationURI: '',
        adURI: ''
      },
      SKUDocId: '',
      stickerDocId: ''
    }
    this.onGridStickersReady = this.onGridStickersReady.bind(this);
    this.onGridScansReady = this.onGridScansReady.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.toggleAddChipModal= this.toggleAddChipModal.bind(this);
    this.toggleUpdateSKUModal= this.toggleUpdateSKUModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
  }
  
  componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps.data) != JSON.stringify(this.props.data)) {
      const SKUCode = nextProps.match.params.SKUCode;
      const SKU = nextProps.data.SKUsBySKUCode[SKUCode];
      if(SKU) this.setState({ SKUDocId: SKU.docId, brand: SKU.brand, code:SKU.code , name: SKU.name, description: SKU.description, pictureURI: SKU.pictureURI ,type: SKU.type, bannerAdURI: SKU.bannerAdURI, videoAd: SKU.videoAd });
    }
  }

  componentDidMount() {
    const SKUCode = this.props.match.params.SKUCode;
    const SKU = this.props.data.SKUsBySKUCode[SKUCode];
    this.updateGridsDatas(this.props);
    if(SKU) this.setState({ SKUDocId: SKU.docId, brand: SKU.brand, code:SKU.code , name: SKU.name, description: SKU.description, pictureURI: SKU.pictureURI ,type: SKU.type, bannerAdURI: SKU.bannerAdURI, videoAd: SKU.videoAd });
  }

  updateGridsDatas(props) {
    this.setState({
      columnDefs: [{
        headerName: "chip id",
        field: "chipId",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Manufacture date",
        valueGetter: (params) => {
          return params.data.manufactureDate ? params.data.manufactureDate : '-';
        },
      },
      {
        headerName: "Expiry date",
        valueGetter: (params) => {
          return params.data.expiryDate ? params.data.expiryDate : '-';
        },
      },
      {
        headerName: "Manufacture location",
        field: "manufactureLocation",
        valueGetter: (params) => {
          return params.data.manufactureLocation ? params.data.manufactureLocation : '-';
        },
      },
      {
        headerName: "Nb of scans",
        sortable: true,
        valueGetter: (params) => {
          return props.data.scansByChipId[params.data.chipId] ? props.data.scansByChipId[params.data.chipId].length : 0;
        },
      },
      {
        headerName: "Link date",
        valueGetter: (params) => {
          return params.data.link ? moment.unix(params.data.link.timestamp).local().format('DD-MM-YYYY HH:mm A') : '-';
        },
      }],
      usersColumnDefs: [
      {
        headerName: "DATE", 
        field: "timestamp", 
        sortable: true, 
        valueGetter: (params) => {
          return moment.unix(params.data.timestamp).local().format('DD-MM-YYYY HH:mm A');
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return nodeA.data.timestamp - nodeB.data.timestamp;
        }
      },
      {
        headerName: "chip id",
        field: "chipId",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Email / Phone number",
        valueGetter: (params) => {
          return props.data.usersById[params.data.userId] ? props.data.usersById[params.data.userId].email ? props.data.usersById[params.data.userId].email : props.data.usersById[params.data.userId].phone : '-';
        },
      },
      {
        headerName: "Name",
        valueGetter: (params) => {
          return props.data.usersById[params.data.userId] ? props.data.usersById[params.data.userId].name : '-';
        },
      }],
    });
  }

  onGridScansReady(params){
    this.gridScansApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridScansApi.sizeColumnsToFit();
  }

  onGridStickersReady(params){
    this.gridStickersApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridStickersApi.sizeColumnsToFit();
  }

  toggleAddChipModal() {
    const { addStickerModal } = this.state;
    this.setState({ addStickerModal: !addStickerModal })
    if(!addStickerModal === false) {
      this.setState({ isStickerUpdate: false, chipId: '', expiryDate: '', manufactureDate: '', manufactureLocation: '', videoURI: DEFAULT_VIDEO_URI });
      this.gridStickersApi.deselectAll();
    }
  }

  toggleUpdateSKUModal() {
    const { updateSKUModal } = this.state;
    this.setState({ updateSKUModal: !updateSKUModal })
  }

  handleChange(event) {
    this.setState({ 
      [event.target.id]: event.target.value 
    });
  }

  handleChangeVideo(event) {
    const { videoAd } = this.state;
    videoAd[event.target.id] =  event.target.value;
    this.setState({ 
      videoAd
    });
  }

  handleChangeCheckbox() {
    const { videoAd } = this.state;
    videoAd.exists = !videoAd.exists;
    this.setState({ 
      videoAd
    });
  }

  validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  addSticker() {
    const { chipId, manufactureDate, manufactureLocation, expiryDate, videoURI } = this.state;
    const SKUCode = this.props.match.params.SKUCode;
    if(!chipId) {
      toast.error('chip Id must be defined.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!videoURI) {
      toast.error('Video URI must be defined.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    const sticker = { SKUCode, chipId, manufactureDate, manufactureLocation, expiryDate, videoURI, from:"DASHBOARD"};
    this.props.addSticker(sticker);
    toast.success('Sticker have been added.', { position: toast.POSITION.TOP_RIGHT });
    this.toggleAddChipModal();
  }

  updateSticker() {
    const { chipId, manufactureDate, manufactureLocation, expiryDate, videoURI, stickerDocId } = this.state;
    const sticker = { docId: stickerDocId, manufactureDate, manufactureLocation, expiryDate, videoURI, from:"DASHBOARD"};
    this.props.updateSticker(sticker);
    toast.success('Sticker have been updated.', { position: toast.POSITION.TOP_RIGHT });
    this.toggleAddChipModal();
  }

  sitckerFormAction() {
    const { isStickerUpdate } = this.state;
    if(isStickerUpdate) this.updateSticker();
    else this.addSticker();
  }

  updateSKU() {
    const { code, name, brand, description, type, pictureURI, videoAd, bannerAdURI, SKUDocId } = this.state;
    if(!code || !name || !description || !type || !pictureURI ||!bannerAdURI) {
      toast.error('Please complete all fields.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!this.validURL(pictureURI)) {
      toast.error('Please use valid URI for field picture.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!this.validURL(bannerAdURI)) {
      toast.error('Please use valid URI for banner Ad.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(videoAd.exists && !this.validURL(videoAd.informationURI)) {
      toast.error('Please use valid URI for field information URI.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(videoAd.exists && !this.validURL(videoAd.adURI)) {
      toast.error('Please use valid URI for field Ad URI.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    const SKU = { docId: SKUDocId, brand, code, name, description, type, pictureURI, videoAd, bannerAdURI, forSaleIn:[], from:"DASHBOARD"};
    this.props.updateSKU(SKU);
    toast.success('SKU have been updated.', { position: toast.POSITION.TOP_RIGHT });
    this.toggleUpdateSKUModal(); 
  }

  renderUpdateSKUModal() {
    const {  updateSKUModal, brand, code, name, description, type, pictureURI, bannerAdURI, videoAd } = this.state;
    return (
      <Modal 
        show={updateSKUModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header>
          <Modal.Title>Update {code}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup>
              <ControlLabel>Code</ControlLabel>
              <FormControl
                type="text"
                id="code"
                value={code}
                placeholder="Enter SKU code"
                disabled
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Brand</ControlLabel>
              <FormControl
                type="text"
                id="brand"
                value={brand}
                placeholder="Enter brand name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                id="name"
                value={name}
                placeholder="Enter SKU name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <FormControl
                type="text"
                id="description"
                componentClass="textarea"
                value={description}
                placeholder="Enter SKU name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Type</ControlLabel>
              <FormControl
                type="text"
                id="type"
                value={type}
                placeholder="Type"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Picture</ControlLabel>
              <FormControl
                type="text"
                id="pictureURI"
                value={pictureURI}
                placeholder="Enter picture link"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Banner URI</ControlLabel>
              <FormControl
                type="text"
                id="bannerAdURI"
                value={bannerAdURI}
                placeholder="Enter banner link"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Video Ad</ControlLabel>
              <div>
                <Switch onChange={this.handleChangeCheckbox} checked={videoAd.exists} />
              </div>
            </FormGroup>
            {
              videoAd.exists &&
            <FormGroup>
              <ControlLabel>Ad Video URI</ControlLabel>
             <FormControl
                type="text"
                id="adURI"
                value={videoAd.adURI}
                placeholder="Enter video link"
                onChange={this.handleChangeVideo}
              />
            <ControlLabel>Information URI</ControlLabel>
             <FormControl
                type="text"
                id="informationURI"
                value={videoAd.informationURI}
                placeholder="Enter info link"
                onChange={this.handleChangeVideo}
              />

            </FormGroup>
            }
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={()=> this.updateSKU()}>Update</Button>
          <Button bsStyle="default" onClick={()=> this.toggleUpdateSKUModal()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  renderAddStickerModal() {
    const { chipId, expiryDate, manufactureDate, manufactureLocation, videoURI, addStickerModal, isStickerUpdate } = this.state;
    const title = isStickerUpdate ? `Update ${chipId}` : 'Add new Sticker';
    const buttonText = isStickerUpdate ? 'Update' : 'Add';
    return (
      <Modal 
        show={addStickerModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup>
              <ControlLabel>Chip Id</ControlLabel>
              <FormControl
                type="text"
                id="chipId"
                value={chipId}
                placeholder="Enter chipId"
                onChange={this.handleChange}
                disabled={isStickerUpdate}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Manufacture date</ControlLabel>
              <FormControl
                type="text"
                id="manufactureDate"
                value={manufactureDate}
                placeholder="Enter manufacture date"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Expiry date</ControlLabel>
              <FormControl
                type="text"
                id="expiryDate"
                value={expiryDate}
                placeholder="Enter expiry date"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Manufacture location</ControlLabel>
              <FormControl
                type="text"
                id="manufactureLocation"
                value={manufactureLocation}
                placeholder="Enter manufacture location"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Video URI</ControlLabel>
              <FormControl
                type="text"
                id="videoURI"
                value={videoURI}
                placeholder="Enter video URI"
                onChange={this.handleChange}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={()=> this.sitckerFormAction()}>{buttonText}</Button>
          <Button bsStyle="default" onClick={()=> this.toggleAddChipModal()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  onBtnStickersExport() {
    const { code } = this.state;
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: `Stickers-${code}.csv` };
    this.gridStickersApi.exportDataAsCsv(params);
  }

  onBtnScansExport() {
    const { code } = this.state;
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: `Scans-${code}.csv` };
    this.gridScansApi.exportDataAsCsv(params);
  }

  onSelectionChanged() {
    if(this.gridStickersApi.getSelectedRows().length === 0) return;
    const selectedRow = this.gridStickersApi.getSelectedRows()[0];
    const {chipId, expiryDate, manufactureDate, manufactureLocation, docId} = selectedRow;
    this.setState({stickerDocId: docId, chipId, expiryDate, manufactureDate, manufactureLocation, isStickerUpdate: true})
    this.toggleAddChipModal();
  }

  render() {
    const SKUCode = this.props.match.params.SKUCode;
    const SKU = this.props.data.SKUsBySKUCode[SKUCode];
    const IMAGES = [];
    let alreadyIn = false;
    if(SKU && SKU.images) {
      SKU.images.forEach((image)=> {
        IMAGES.push({ src: image, thumbnail: image, thumbnailWidth: 200, thumbnailHeight: 200 });
      });
    }

    if(SKU && !SKU.images) {
      IMAGES.push({ src: SKU.pictureURI, thumbnail:  SKU.pictureURI, thumbnailWidth: 200, thumbnailHeight: 200});
    } else if(SKU && SKU.pictureURI != SKU.images[SKU.images.length-1]){
      IMAGES.push({ src: SKU.pictureURI, thumbnail:  SKU.pictureURI, thumbnailWidth: 200, thumbnailHeight: 200});
    }

    return (
      <div className="content">
        {this.renderAddStickerModal()}
        {this.renderUpdateSKUModal()}
        <Grid>
          <Row>
            <Col lg={12}>
              <Card
                title="Actions"
                content={
                  <div>
                    <Button style={{marginRight: 10}} onClick={()=> this.toggleAddChipModal()}>Add new sticker for this SKU</Button>
                    <Button onClick={()=> this.toggleUpdateSKUModal()}>Update SKU</Button>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={SKU ? SKU.name : '...'}
                category="SKU INFO"
                ctTableFullWidth
                ctTableResponsive
                content={
                 <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Brand</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{SKU ? SKU.code : '...'}</td>
                      <td>{SKU ? SKU.brand : '...'}</td>
                      <td>{SKU ? SKU.name : '...'}</td>
                      <td>{SKU ? SKU.description : '...'}</td>
                      <td>{SKU ? SKU.type : '...'}</td>
                    </tr>
                  </tbody>
                </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
             <Card
                title="Images"
                category="SKU INFO"
                content={
                <div style={{textAlign: 'center', height: 200}}>
                    { <Gallery images={IMAGES}/>}
                </div>
                }
              />
            </Col>
           </Row>
           <Row>
            <Col md={6}>
              <Card
                title="Stickers"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnStickersExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridStickersReady}
                      pagination={true}
                      rowSelection='single'
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.data.chipsBySKUCode[SKUCode]}
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Scans"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnScansExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridScansReady}
                      pagination={true}
                      rowSelection='single'
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={this.state.usersColumnDefs}
                      rowData={this.props.data.scansBySKUCode[SKUCode]}
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    addSticker: (sticker) => {
      dispatch(addStickerThunk(sticker));
    },
    updateSKU: (SKU) => {
      dispatch(updateSKUThunk(SKU));
    },
    updateSticker: (sticker) => {
      dispatch(updateStickerThunk(sticker));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKU);
