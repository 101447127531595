/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, Button, Modal, FormGroup, FormControl, ControlLabel, HelpBlock, Checkbox} from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import HighchartsReact from 'highcharts-react-official';
import { toast } from 'react-toastify';
import Highcharts from 'highcharts';
import addTreemapModule from 'highcharts/modules/treemap';
import { addSKUThunk } from '../reducers/actions';
import Switch from "react-switch";
import config from "../config";


addTreemapModule(Highcharts);
class SKUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treemapOptions: {},
      addSKUModal: false,
      code: '', 
      name: '', 
      description: '', 
      type: '', 
      pictureURI: '',
      brand: '',
      bannerAdURI: 'https://firebasestorage.googleapis.com/v0/b/the-verify-app.appspot.com/o/Banners%2Fverifybanner.png?alt=media&token=de9fe126-6ea8-4158-877a-b40aaf21c908',
      videoAd: {
        exists: true,
        informationURI: '',
        adURI: 'https://firebasestorage.googleapis.com/v0/b/the-verify-app.appspot.com/o/Videos%2FtheVerifyApp.mp4?alt=media&token=a6cff87c-ad91-4669-8e6a-033c5e0ea62c'
      },
    }
    this.onGridReady = this.onGridReady.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addSKU = this.addSKU.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
  }
  
  componentWillReceiveProps(nextProps) {
    this.updateTreemapOptions(nextProps);
  }

  componentDidMount() {
    this.setState({
      columnDefs: [{
        headerName: "Code",
        field: "code",
        sortable: true,
        filter: true,
      }, 
      {
        headerName: "Brand",
        field: "brand",
        sortable: false,
        filter: true,
      },
      {
        headerName: "Name", 
        field: "name",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Type",
        field: "type",
        sortable: false,
        filter: true,
      },
      {
        headerName: "NB OF CHIPS", 
        sortable: true,
        valueGetter: (params) => {
          return this.props.data.chipsBySKUCode[params.data.code] ? this.props.data.chipsBySKUCode[params.data.code].length : 0;
        },
      },
      {
        headerName: "NB OF SCANS", 
        sortable: true,
        valueGetter: (params) => {
          return this.props.data.scansBySKUCode[params.data.code] ? this.props.data.scansBySKUCode[params.data.code].length : 0;
        },
      }],
    });
    this.updateTreemapOptions(this.props);
  }


  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  
  countUnique(iterable) {
    return new Set(iterable).size;
  }
  

  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    const SKUCode = selectedRow.code;
    this.props.history.push(`/admin/SKU/${SKUCode}`);
  }

  updateTreemapOptions(nextProps) {
    const data = [];
    Object.keys(nextProps.data.SKUsByType).forEach((key, i)=>{
      data.push({ name:key, value: nextProps.data.SKUsByType[key], color: config.COLORS[i] })
    });
    this.setState({
      treemapOptions: {
        credits: {
          enabled: false
        },
        chart:{
          height: 300,
        },
        title: {
          text: 'SKUs BY TYPE',
        },
        series: [{
          type: "treemap",
          layoutAlgorithm: 'squarified',
          alternateStartingDirection: true,
          levels: [{
            level: 1,
            layoutAlgorithm: 'squarified',
            dataLabels: {
              enabled: true,
              align: 'center',
              verticalAlign: 'middle',
              style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
              }
            }
          }],
          data,
        }],
      }
    });
  }

  validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  addSKU() {
    const { brand, code, name, description, type, pictureURI, videoAd, bannerAdURI } = this.state;
    if(!code || !name || !brand || !description || !type || !pictureURI ||!bannerAdURI) {
      toast.error('Please complete all fields.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!this.validURL(pictureURI)) {
      toast.error('Please use valid URI for field picture.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!this.validURL(bannerAdURI)) {
      toast.error('Please use valid URI for banner Ad.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(videoAd.exists && !this.validURL(videoAd.informationURI)) {
      toast.error('Please use valid URI for field information URI.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(videoAd.exists && !this.validURL(videoAd.adURI)) {
      toast.error('Please use valid URI for field Ad URI.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    const SKU = { brand, code, name, description, type, pictureURI, videoAd, bannerAdURI, forSaleIn:[], from:"DASHBOARD"};
    this.props.addSKU(SKU);
    toast.success('SKU have been added, you will be redirected.', { position: toast.POSITION.TOP_RIGHT });
    setTimeout(() => { 
      this.toggleModal(); 
      this.props.history.push(`/admin/SKU/${code}`);
    }, 1000);
  }

  toggleModal() {
    const { addSKUModal } = this.state;
    this.setState({ addSKUModal: !addSKUModal })
  }

  handleChange(event) {
    this.setState({ 
      [event.target.id]: event.target.value 
    });
  }

  handleChangeCheckbox() {
    const { videoAd } = this.state;
    videoAd.exists = !videoAd.exists;
    this.setState({ 
      videoAd
    });
  }

  handleChangeVideo(event) {
    const { videoAd } = this.state;
    videoAd[event.target.id] =  event.target.value;
    this.setState({ 
      videoAd
    });
  }

  onBtnExport() {
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: 'SKUs.csv' };
    this.gridApi.exportDataAsCsv(params);
  }

  renderAddSKUModal() {
    const { code, name, brand, description, type, pictureURI, addSKUModal, videoAd, bannerAdURI} = this.state;
    return (
      <Modal 
        show={addSKUModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header>
          <Modal.Title>Add new SKU</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup>
              <ControlLabel>Code</ControlLabel>
              <FormControl
                type="text"
                id="code"
                value={code}
                placeholder="Enter SKU code"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Brand</ControlLabel>
              <FormControl
                type="text"
                id="brand"
                value={brand}
                placeholder="Enter brand name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                id="name"
                value={name}
                placeholder="Enter SKU name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <FormControl
                type="text"
                id="description"
                componentClass="textarea"
                value={description}
                placeholder="Enter SKU name"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Type</ControlLabel>
              <FormControl
                type="text"
                id="type"
                value={type}
                placeholder="Type"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Picture</ControlLabel>
              <FormControl
                type="text"
                id="pictureURI"
                value={pictureURI}
                placeholder="Enter picture link"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Banner URI</ControlLabel>
              <FormControl
                type="text"
                id="bannerAdURI"
                value={bannerAdURI}
                placeholder="Enter banner link"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Video Ad</ControlLabel>
              <div>
                <Switch onChange={this.handleChangeCheckbox} checked={videoAd.exists} />
              </div>
            </FormGroup>
            {
              videoAd.exists &&
            <FormGroup>
              <ControlLabel>Ad Video URI</ControlLabel>
             <FormControl
                type="text"
                id="adURI"
                value={videoAd.adURI}
                placeholder="Enter video link"
                onChange={this.handleChangeVideo}
              />
            <ControlLabel>Information URI</ControlLabel>
             <FormControl
                type="text"
                id="informationURI"
                value={videoAd.informationURI}
                placeholder="Enter info link"
                onChange={this.handleChangeVideo}
              />

            </FormGroup>
            }
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={()=> this.addSKU()}>Add</Button>
          <Button bsStyle="default" onClick={()=> this.toggleModal()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { treemapOptions } = this.state;
    return (
      <div className="content">
        {this.renderAddSKUModal()}
        <Grid>
          <Row>
            <Col lg={4} sm={6}>
              <Card
                title="Actions"
                content={
                  <Button onClick={()=> this.toggleModal()}>Add SKU</Button>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
            </Col>
            <Col lg={4} sm={6}>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-tag text-info" />}
                statsText="SKUs"
                statsValue={this.props.data.SKUs.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="Scans per SKU"
                statsValue={this.props.data.SKUs.length > 0 ? (this.props.data.scans.length/this.props.data.SKUs.length).toFixed(0) : 0}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-hashtag text-info" />}
                statsText="SKU type"
                statsValue={Object.keys(this.props.data.SKUsByType).length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <Card
                title="SKUs"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridReady}
                      pagination={true}
                      rowSelection='single'
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.data.SKUs} 
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                }
              />
            </Col>
            <Col lg={5} sm={5}>
             <Card
                statsIcon="fa fa-clock-o"
                title="SKUs by type"
                category="Actual State"
                stats="All SKUs"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={treemapOptions}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    addSKU: (SKU) => {
      dispatch(addSKUThunk(SKU));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SKUs);
