/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Modal, Button, FormControl } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { connect } from 'react-redux';
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { style } from "variables/Variables.jsx";
import routes from "routes.js";
import image from "assets/img/sidebar-3.jpg";
import firebase from '../Firebase';
import { toast } from 'react-toastify';
import { 
  getAllTagsThunk, 
  getAllScansThunk, 
  getAllSKUsThunk, 
  getAllEventsThunk,
  getAllUsersThunk,
  watchAllScansThunk, 
  watchAllSKUsThunk, 
  watchIsLoginThunk, 
  loginThunk 
} from '../reducers/actions';
const async = require('async');

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
      isLogin: true,
      number: '',
      showCode: false,
      code: '',
    };
    this.onSendSMS = this.onSendSMS.bind(this);
    this.onConfirmCode = this.onConfirmCode.bind(this);
    this.cleanLoginForm = this.cleanLoginForm.bind(this);
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  componentDidMount() {
    this.loadData(this.props);
  }

   componentWillReceiveProps(nextProps)  {
    if(JSON.stringify(nextProps.data) != JSON.stringify(this.props.data)) {
      this.loadData(nextProps);
    }
  }

  loadData(props) {
    this.props.watchUser((isLogin)=>{
      if(isLogin) { 
        const eid = props.data.user.data.entityId;
        if(eid) {
          this.props.getAllSKUs(eid);
          this.props.getAllTags(eid);
          this.props.getAllScans(eid);
          this.props.getAllEvents(eid);
          this.props.getAllUsers(props.data.users);
          this.setState({ isLogin: true }); 
        }
      } else {
        this.setState({ isLogin: false });
        this.initCaptcha();
      }
    });
  }

  initCaptcha() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
      'size': 'normal',
      'callback': function (response) {
      },
      'expired-callback': function () {
      }
     });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  cleanLoginForm() {
    this.setState({
      number: '',
      showCode: false,
      code: null,
    });
  }

  handleChange(id, event) {
    this.setState({
      [id]: event.target.value,
    });
  }

  onSendSMS() {
    const { number } = this.state;
    const fullNumber = `+${number}`;
    if(!number) {
      toast.error('The phone number is invalid.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    this.props.login(fullNumber, window.recaptchaVerifier, (status, confirmResult) => {
      if(status) {
       this.setState({ showCode: true });
       this.confirmationResult = confirmResult;
      } else {
        toast.error('Error while trying to send sms.', { position: toast.POSITION.TOP_RIGHT });
        this.setState({ showCode: false});
      }
    });
  }

  onConfirmCode() {
    const { code } = this.state;
    if(!code || code.length != 6) {
      toast.error('The code format is invalid.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    this.confirmationResult.confirm(code)
    .then((user) => {
      if(user) {
        this.cleanLoginForm();
        toast.success('You will be redirected.', { position: toast.POSITION.TOP_RIGHT });
        return;
      }
      // Alert.alert('SMS Login', 'Code Confirmed!');
    })
   .catch((error) => {
      if(error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error('The code is invalid.', { position: toast.POSITION.TOP_RIGHT });
      }
    });
  }

  renderLogin() {
    const { isLogin, number, showCode, code } = this.state;
    let title = 'You have to be logged in to see this page';
    let buttonActionText = 'SEND SMS'
    let action = this.onSendSMS;
    if(showCode) {
      title = 'Enter verification code';
      buttonActionText = 'LOGIN'
      action = this.onConfirmCode;
    }
    return (
      <Modal 
        show={!isLogin}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          showCode ?
          <div>
            <label> Enter your verification code</label>
            <FormControl
              type="text"
              value={code}
              placeholder={'Enter verification code'}
              onChange={event => this.handleChange('code', event)}
            />
          </div>
          :
          <div>
            <label> Enter your phone number [use international format] ex: 971123456789)</label>
            <FormControl
              type="text"
              value={number}
              placeholder='971123456789'
              onChange={event => this.handleChange('number', event)}
            />
          </div>

          }
          {!showCode && <div style={{marginTop: 10}} ref={(ref)=>this.recaptcha=ref}></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={action}>{buttonActionText}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div className="wrapper">
        {this.renderLogin()}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={routes} image={this.state.image}
        color={this.state.color}
        hasImage={this.state.hasImage}/>
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllTags: (entityId) => {
      dispatch(getAllTagsThunk(entityId));
    },
    getAllScans: (entityId) => {
      dispatch(getAllScansThunk(entityId));
    },
    getAllEvents: (entityId) => {
      dispatch(getAllEventsThunk(entityId));
    },
    getAllSKUs: (entityId) => {
      dispatch(getAllSKUsThunk(entityId));
    },
    getAllUsers: (users) => {
      dispatch(getAllUsersThunk(users));
    },
    watchAllScans: (entityId) => {
      dispatch(watchAllScansThunk(entityId));
    },
    watchAllSKUs: (entityId) => {
      dispatch(watchAllSKUsThunk(entityId));
    },
    watchUser: (cb) => {
      dispatch(watchIsLoginThunk(cb));
    },
    login: (fullNumber, captcha, cb) => {
      dispatch(loginThunk(fullNumber, captcha, cb));
    }
  };
};

const mapStateToProps = state => ({
  data: state.globalReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
