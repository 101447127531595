/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, ButtonGroup, Button } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { ScansMap } from "components/Maps/ScansMap.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import addTreemapModule from 'highcharts/modules/treemap';
import addFunnelModule from 'highcharts/modules/funnel';
import  Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import config from "../config";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
addFunnelModule(Highcharts);
class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barOptions: {},
      funnelOptions: {},
    }
  }

  countUnique(iterable) {
    return new Set(iterable).size;
  }

  componentDidMount() {
    this.updateBarOptions(this.props);
    this.updateFunnelOptions(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateBarOptions(nextProps);
    this.updateFunnelOptions(nextProps);
  }

  updateFunnelOptions(nextProps) {
    const eventsByName = nextProps.data.eventsByName;
    this.setState({
      funnelOptions:{
        credits: {
          enabled: false
        },
        chart: {
            type: 'funnel'
        },
        title: {
            text: null,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b> ({point.y:,.0f})',
              softConnector: true
            },
            center: ['50%', '50%'],
            neckWidth: '30%',
            neckHeight: '25%',
            width: '80%'
          }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Unique users',
            data: [
              ['Scan', nextProps.data.eventsByName['success_scan']],
              ['Learn more', nextProps.data.eventsByName['press_learn_more_button']],
              ['Timeline', nextProps.data.eventsByName['press_timeline_button']],
            ]
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                      inside: true
                  },
                  center: ['50%', '50%'],
                  width: '100%'
                }
              }
            }
          }]
        }
      }
    });
  }



  updateBarOptions(nextProps) {
    const categories = Object.keys(nextProps.data.eventsByName);
    const data = Object.values(nextProps.data.eventsByName);
    this.setState({
      barOptions:
      {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column'
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          categories,
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
              text: 'Nb'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          colors: config.COLORS,
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:config.COLORS,
          shadow: true
        },
        series: [{
          showInLegend: false,             
          data
        }]
      }
    });
  }

  render() {
    const { barOptions, funnelOptions } = this.state;
    const users = this.props.data.scans.map(a => a.userId);
    const nbUsers = this.countUnique(users);
    return (
      <div className="content">
        <Grid>
          <Row>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="Scans"
                statsValue={this.props.data.scans.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-mouse-pointer text-info" />}
                statsText="Nb of CTAs"
                statsValue={this.props.data.events.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-more" />}
                statsText="CTA 1: Learn More"
                statsValue={this.props.data.eventsByName.press_learn_more_button || 0}
                statsIcon={<i className="fa fa-calendar" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal" />}
                statsText="CTA 2: Ad Displayed"
                statsValue={this.props.data.eventsByName.display_ad || 0 }
                statsIcon={<i className="fa fa-calendar" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-video" />}
                statsText="CTA 3: Video Played"
                statsValue={this.props.data.eventsByName.play_video || 0 }
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-clock" />}
                statsText="CTA 4: Timeline click"
                statsValue={this.props.data.eventsByName.press_timeline_button || 0 }
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card
                title="Events by type"
                category="All period"
                statsIcon="fa fa-clock-o"
                stats="All users"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={barOptions}
                  />
                }
              />
            </Col>
            <Col lg={6}>
              <Card
                title="Funnel"
                category="All period"
                statsIcon="fa fa-clock-o"
                stats="All users"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={funnelOptions}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

export default connect(mapStateToProps, null)(Marketing);
