import merge from 'lodash/merge';
import moment from 'moment';

const INITIAL_STATE = {
  tags: [],
  linked: [],
  scans: [],
  users: [],
  events: [],
  liveScans: [],
  SKUs: [],
  SKUsByType: {},
  usersById: {},
  usersByCity: {},
  user: {
    data: {},
    isLogin: false
  },
  scansByPeriod: {
    'ALL': {},
    '1D': [],
    '7D': [],
    '30D': [],
    '90D': []
  },
  scansChart: [],
  scansByChipId: {},
  scansByUserId: {},
  scansBySKUCode: {},
  chipsBySKUCode: {},
  OSByUserNb: {
    ANDROID: 0,
    IOS: 0,
  },
  accountTypeByUserNb: {
    FACEBOOK: 0,
    PHONE: 0,
  },
  eventsByName: {},
  scansActivityByPeriod: { 
    'ALL': {},
    '1D': {},
    '7D': {},
    '30D': {},
    '90D': {}
  },
  SKUsBySKUCode: {},
};

function scansSort(scans) {
  const scansByChipId = {};
  const scansByUserId = {};
  const scansBySKUCode = {};
  const users = [];
  const scansByPeriod = {
    'ALL': [],
    '1D': [],
    '7D': [],
    '30D': [],
    '90D': []
  };
  const scansChart = [];
  const current =  moment().local();
  scans.forEach((scan)=>{
    const scanDate = moment.unix(scan.timestamp).local();
    const diff = current.diff(scanDate, 'hours');
    if(!scansByChipId[scan.chipId]) scansByChipId[scan.chipId] = [scan];
    else scansByChipId[scan.chipId].push(scan);

    if(!scansByUserId[scan.userId]) scansByUserId[scan.userId] = [scan];
    else scansByUserId[scan.userId].push(scan);

    if(!scansBySKUCode[scan.SKUCode]) scansBySKUCode[scan.SKUCode] = [scan];
    else scansBySKUCode[scan.SKUCode].push(scan);
    
    scansByPeriod['ALL'].push(scan);
    if(diff < 24) {
      scansByPeriod['1D'].push(scan);
    }
    if(diff < (24*7)) {
      scansByPeriod['7D'].push(scan);
    }
    if(diff < (24*30)) {
      scansByPeriod['30D'].push(scan);
    }
    if(diff < (24*90)) {
      scansByPeriod['90D'].push(scan);
    }
    scansChart.push([scan.timestamp*1000, 1]);
    //
    if(!users.includes(scan.userId)) users.push(scan.userId);
  });
  return { scansByChipId, scansByUserId, scansBySKUCode, scansByPeriod, scansChart, users };
}

function chipsData(chips) {
  const chipsBySKUCode = {};
  const linked = [];
  chips.forEach((chip)=> {
    if(chip.SKUCode) {
      linked.push(chip);
      if(chipsBySKUCode[chip.SKUCode]) chipsBySKUCode[chip.SKUCode].push(chip);
      else chipsBySKUCode[chip.SKUCode] = [chip];
    }
  });
  return { chipsBySKUCode, linked};
}

function scansActivityByPeriod(SBP) {
  const scansActivityByPeriod = {
    'ALL': {},
    '1D': {},
    '7D': {},
    '30D': {},
    '90D': {}
  };
  Object.keys(SBP).forEach((period)=>{
    const scans = SBP[period];
    scans.forEach((scan)=> {
      let date;
      switch(period) {
        case '1D':
          date = moment.unix(scan.timestamp).local().format('DD-MM HH:mm');
          break;
        default:
          date = moment.unix(scan.timestamp).local().format('DD-MM-YYYY');
      }
      scansActivityByPeriod[period][date] = scansActivityByPeriod[period][date] ? scansActivityByPeriod[period][date]+1 : 1;
    });
  });
  return scansActivityByPeriod;
}

function SKUsData(SKUs) {
  const SKUsBySKUCode = {};
  const SKUsByType = {};
  SKUs.forEach((SKU)=> {
    SKUsBySKUCode[SKU.code] = SKU;
    if(!SKU.type) SKU.type = "NO TYPE";
    SKUsByType[SKU.type] = SKUsByType[SKU.type] ? SKUsByType[SKU.type]+1 : 1;
  });
  return { SKUsBySKUCode, SKUsByType };
}

function eventsData(events) {
  const OSByUserNb = {
    IOS: 0,
    ANDROID: 0,
  };
  const usersByCity = {};
  const eventsByName = {};
  const userId = {
    IOS: [],
    ANDROID: [],
  };
  events.forEach((event)=> {
    if(!userId[event.OS].includes(event.userId)) {
      userId[event.OS].push(event.userId);
      OSByUserNb[event.OS]+=1;
    }
    eventsByName[event.event_name] = eventsByName[event.event_name] ? eventsByName[event.event_name]+1 : 1;

    usersByCity[event.geo.city] = usersByCity[event.geo.city] ? usersByCity[event.geo.city]+1 : 1;
  });
  return { OSByUserNb, eventsByName, usersByCity };
}

function userData(users) {
  const accountTypeByUserNb = {
    FACEBOOK: 0,
    PHONE: 0,
  };
  const usersById = {};
  Object.keys(users).forEach((key)=> {
    const user = users[key];
    usersById[key] = user;
    if(user.email) accountTypeByUserNb['FACEBOOK']+=1;
    else accountTypeByUserNb['PHONE']+=1;
  });
  return { accountTypeByUserNb, usersById };
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_ALL_TAGS':
      const sortChips = chipsData(action.data);
      return merge({}, state, { tags: action.data, chipsBySKUCode: sortChips.chipsBySKUCode, linked: sortChips.linked});
    case 'GET_ALL_SCANS':
      const sortScans = scansSort(action.data);
      const SABP = scansActivityByPeriod(sortScans.scansByPeriod);
      return merge({}, state, { scans: action.data, scansByChipId: sortScans.scansByChipId, scansByUserId: sortScans.scansByUserId, scansBySKUCode: sortScans.scansBySKUCode, scansByPeriod: sortScans.scansByPeriod, scansActivityByPeriod: SABP, scansChart: sortScans.scansChart, users: sortScans.users });
    case 'GET_ALL_SKUs':
      const SBDATA = SKUsData(action.data);
      return merge({}, state, { SKUs: action.data, SKUsBySKUCode: SBDATA.SKUsBySKUCode, SKUsByType: SBDATA.SKUsByType });
    case 'GET_ALL_EVENTS':
      const ED = eventsData(action.data);
      return merge({}, state, { events: action.data, OSByUserNb: ED.OSByUserNb, eventsByName: ED.eventsByName, usersByCity: ED.usersByCity });
    case 'GET_ALL_USERS':
      const UBCT = userData(action.data);
      return merge({}, state, { usersById: UBCT.usersById, accountTypeByUserNb: UBCT.accountTypeByUserNb });
    case 'SET_USER':
      return merge({}, state, { user: action.data });
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state
  }
};

export default reducer;
