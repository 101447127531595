import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {};

const config = {
  apiKey: "AIzaSyB6m844CNDwn8JNb-WBZmnCaN9t0rFE6I8",
  authDomain: "the-verify-app.firebaseapp.com",
  databaseURL: "https://the-verify-app.firebaseio.com",
  projectId: "the-verify-app",
  storageBucket: "the-verify-app.appspot.com",
  messagingSenderId: "910852277268",
  appId: "1:910852277268:web:b991390c8949dcc1"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;