/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import pin from "assets/img/pin.png";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

class Scans extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.onGridReady = this.onGridReady.bind(this);
  }

  componentDidMount() {
    this.setState({
      columnDefs: [{
        headerName: "CHIP ID",
        field: "chipId",
        sortable: true,
        filter: true,
      }, {
        headerName: "SKU CODE", 
        field: "SKUCode",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Name",
        sortable: true,
        filter: true,
        valueGetter: (params) => {
          if(this.props.data.SKUsBySKUCode[params.data.SKUCode])
          return this.props.data.SKUsBySKUCode[params.data.SKUCode].name;
        },
      },
      {
        headerName: "TIMESTAMP", 
        field: "timestamp", 
        sortable: true, 
        valueGetter: (params) => {
          return moment.unix(params.data.timestamp).local().format('DD-MM-YYYY HH:mm A');
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return nodeA.data.timestamp - nodeB.data.timestamp;
        }
      },
      {
        headerName: "LOCATION",
        field: "location",
        resizable: true,
        filter: true, 
      },
      {
        headerName: "USER ID",
        field: "userId",
        filter: true, 
      }],
    });
  }
  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  
  countUnique(iterable) {
    return new Set(iterable).size;
  }

  onBtnExport() {
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: 'Scans.csv' };
    this.gridApi.exportDataAsCsv(params);
  }


  render() {
    const users = this.props.data.scans.map(a => a.userId);
    const CustomMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={12}
          defaultCenter={{ lat: 25.2048, lng: 55.2708 }}
          defaultOptions={{
            scrollwheel: false,
            zoomControl: true
          }}
        > 
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >   
          { 
            this.props.data.scans.map((scan, key)=>{
              if(scan.coordinates)
              return <Marker icon={pin} key={key} position={{ lat: scan.coordinates.latitude, lng: scan.coordinates.longitude }} />;
            })
            
          }
          </MarkerClusterer>
        </GoogleMap>
      ))
    );

    return (
      <div className="content">
        <Grid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="Scans"
                statsValue={this.props.data.scans.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-microchip text-info" />}
                statsText="Tags scanned"
                statsValue={Object.keys(this.props.data.scansByChipId).length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-tag text-info" />}
                statsText="SKUs scanned"
                statsValue={Object.keys(this.props.data.scansBySKUCode).length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card
                title="Scans"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridReady}
                      pagination={true}
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={this.state.columnDefs}
                      rowData={this.props.data.scans} />
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Maps"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <CustomMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6m844CNDwn8JNb-WBZmnCaN9t0rFE6I8"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `500px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

export default connect(mapStateToProps, null)(Scans);
