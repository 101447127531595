/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  countUnique(iterable) {
    return new Set(iterable).size;
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  getScanEvolution() {
    const PerLinked = (this.props.data.linked.length/this.props.data.tags.length)*100;
    const PerNotLinked = 100-PerLinked;
    const data = {
      labels: [`${PerLinked.toFixed(1)}%`, `${PerNotLinked.toFixed(1)}%`],
      series: [PerLinked, PerNotLinked]
    };
    return data;
  }

  render() {
    const users = this.props.data.scans.map(a => a.userId);
    const legend = this.createLegend({ names: ["Linked", "Not Linked"], types: ["info", "danger", "warning"]});
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-microchip text-info" />}
                statsText="Stickers"
                statsValue={this.props.data.tags.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-link text-success" />}
                statsText="Linked"
                statsValue={this.props.data.linked.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="Scans"
                statsValue={this.props.data.scans.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-users text-info" />}
                statsText="Users"
                statsValue={this.countUnique(users)}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Stickers Statistics"
                category="Actual State"
                stats="All Stickers"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={this.getScanEvolution()} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{legend}</div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

export default connect(mapStateToProps, null)(Dashboard);
