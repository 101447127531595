/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, ButtonGroup, Button, Modal, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { ScansMap } from "components/Maps/ScansMap.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import { toast } from 'react-toastify';
import addTreemapModule from 'highcharts/modules/treemap';
import  Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import config from "../config";
import { addStickerThunk } from '../reducers/actions';
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
addTreemapModule(Highcharts);
class Stickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SKUs: this.props.data.SKUs,
      addStickerModal: false,
      period: {
        map: '90D',
        chart: '90D', 
      },
      treemapOptions: {},
      pieOptions: {},
      chipId: '',
      expiryDate: '',
      manufactureDate: '',
      manufactureLocation: '',
      videoURI: 'https://firebasestorage.googleapis.com/v0/b/the-verify-app.appspot.com/o/Videos%2FtheVerifyApp.mp4?alt=media&token=a6cff87c-ad91-4669-8e6a-033c5e0ea62c'
    }
    this.onGridReady = this.onGridReady.bind(this);
    this.toggleModal= this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    this.updateTreemapOptions(this.props);
    this.updatePieOptions(this.props);
    this.updateStickersColumnDefs(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updatePieOptions(nextProps);
    this.updateTreemapOptions(nextProps);
    this.updateStickersColumnDefs(nextProps);
  }

  toggleModal() {
    const { addStickerModal } = this.state;
    this.setState({ addStickerModal: !addStickerModal })
  }



  updateStickersColumnDefs(nextProps) {
    this.setState({
      stickersColumnDefs: [{
        headerName: "ID",
        field: "chipId",
        sortable: true,
        filter: true,
      }, 
      {
        headerName: "Linked", 
        sortable: true,
        valueGetter: (params) => {
          return params.data.SKUCode ? "LINKED" : "NOT LINKED";
        },
      },
      {
        headerName: "Linked To [SKU CODE]", 
        sortable: true,
        filter: true,
        valueGetter: (params) => {
          return params.data.SKUCode ? params.data.SKUCode : "-";
        },
      },
      {

        headerName: "Linked To [SKU NAME]", 
        sortable: true,
        filter: true,
        valueGetter: (params) => {
          return params.data.SKUCode ? this.props.data.SKUsBySKUCode[params.data.SKUCode].name : "-";
        },
      },
      {
        headerName: "NB OF SCAN", 
        sortable: true,
        valueGetter: (params) => {
          return nextProps.data.scansByChipId[params.data.chipId] ? nextProps.data.scansByChipId[params.data.chipId].length : 0;
        },
      },

      ],
    });
  }

  updatePieOptions(nextProps) {
    const data = [];
    nextProps.data.SKUs.forEach((sku)=>{
      if(nextProps.data.scansBySKUCode[sku.code] > 0) {
        data.push({name: sku.name, y:nextProps.data.scansBySKUCode[sku.code] });
      }
    });
    this.setState({
      pieOptions: {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 300,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'SCANS',
          colorByPoint: true,
          data,
        }]
      }
    });
  }

  updateTreemapOptions(nextProps) {
    const obj = {};
    const data = [];
    const SKUsBySKUCode = nextProps.data.SKUsBySKUCode;
    nextProps.data.scans.forEach((scan)=>{
      if(scan.SKUCode) {
        const SKU = SKUsBySKUCode[scan.SKUCode];
        if(SKU) {
          const type = SKU.type;
          obj[type] = obj[type] ? obj[type]+1 : 1;
        }
      }
    });
    Object.keys(obj).forEach((key, i)=>{
      data.push({ name:key, value: obj[key], color: config.COLORS[i]})
    });

    this.setState({
      treemapOptions: {
        credits: {
          enabled: false
        },
        chart:{
          height: 300,
        },
        title: {
          text: "SCANS BY SKU TYPE",
        },
        series: [{
          type: "treemap",
          layoutAlgorithm: 'squarified',
          alternateStartingDirection: true,
          levels: [{
            level: 1,
            layoutAlgorithm: 'squarified',
            dataLabels: {
              enabled: true,
              align: 'center',
              verticalAlign: 'middle',
              style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
              }
            }
          }],
          data,
        }],
      }
    });
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  
  countUnique(iterable) {
    return new Set(iterable).size;
  }

  getMostScannedSKUs() {
    return this.props.data.SKUs.filter((sku)=>{
      return this.props.data.scansBySKUCode[sku.code] > 0;
    });
  }

  getPercentLinkedScanned() {
    const scanned = {};
    this.props.data.linked.forEach((chip)=>{
      if(this.props.data.scansByChipId[chip.chipId]>0)
      scanned[chip.chipId] = true;
    });
    const nbScanned = Object.values(scanned).reduce((a, item) => a + (item === true ? 1 : 0), 0);
    const percentLinkedScanned = this.props.data.linked.length> 0 ? (nbScanned / this.props.data.linked.length) * 100 : 0;
    return percentLinkedScanned.toFixed(1);
  }

  getScanAcivity() {
    const { period } = this.state;
    return {
      labels: Object.keys(this.props.data.scansActivityByPeriod[period.chart]),
      series: [Object.values(this.props.data.scansActivityByPeriod[period.chart])]
    }
  }

  getScanEvolution() {
    const legend = this.createLegend({ names: ["Linked", "Not Linked"], types: ["info", "danger", "warning"]});
    const PerLinked = (this.props.data.linked.length/this.props.data.tags.length)*100;
    const PerNotLinked = 100-PerLinked;
    const data = {
      labels: [`${PerLinked.toFixed(1)}%`, `${PerNotLinked.toFixed(1)}%`],
      series: [PerLinked, PerNotLinked]
    };
    return { data, legend };
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  selectPeriod(type, newPeriod) {
    const { period } = this.state;
    period[type] = newPeriod;
    this.setState({ 
      period
    });
  }

  handleChange(event) {
    this.setState({ 
      [event.target.id]: event.target.value 
    });
  }


  addSticker() {
    const { chipId, manufactureDate, manufactureLocation, expiryDate, videoURI } = this.state;
    if(!chipId) {
      toast.error('chip Id must be defined.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    if(!videoURI) {
      toast.error('Video URI must be defined.', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    const sticker = { chipId, manufactureDate, manufactureLocation, expiryDate, videoURI, from:"DASHBOARD"};
    this.props.addSticker(sticker);
    toast.success('Sticker have been added.', { position: toast.POSITION.TOP_RIGHT });
    this.toggleModal();
  }

  onBtnExport() {
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: 'Stickers.csv' };
    this.gridApi.exportDataAsCsv(params);
  }

  renderPeriodButtons(type) {
    return (<ButtonGroup>
      <Button onClick={()=> this.selectPeriod(type, '1D')}>1D</Button>
      <Button onClick={()=> this.selectPeriod(type, '7D')}>7D</Button>
      <Button onClick={()=> this.selectPeriod(type, '30D')}>30D</Button>
      <Button onClick={()=> this.selectPeriod(type, '90D')}>90D</Button>
      <Button onClick={()=> this.selectPeriod(type, 'ALL')}>ALL</Button>
    </ButtonGroup>);
  }


  renderAddStickerModal() {
    const { chipId, expiryDate, manufactureDate, manufactureLocation, videoURI, addStickerModal } = this.state;
    return (
      <Modal 
        show={addStickerModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header>
          <Modal.Title>Add new Sticker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup>
              <ControlLabel>Chip Id</ControlLabel>
              <FormControl
                type="text"
                id="chipId"
                value={chipId}
                placeholder="Enter chipId"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Manufacture date</ControlLabel>
              <FormControl
                type="text"
                id="manufactureDate"
                value={manufactureDate}
                placeholder="Enter manufacture date"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Expiry date</ControlLabel>
              <FormControl
                type="text"
                id="expiryDate"
                value={expiryDate}
                placeholder="Enter expiry date"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Manufacture location</ControlLabel>
              <FormControl
                type="text"
                id="manufactureLocation"
                value={manufactureLocation}
                placeholder="Enter manufacture location"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Video URI</ControlLabel>
              <FormControl
                type="text"
                id="videoURI"
                value={videoURI}
                placeholder="Enter video URI"
                onChange={this.handleChange}
              />
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={()=> this.addSticker()}>Add</Button>
          <Button bsStyle="default" onClick={()=> this.toggleModal()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  render() {
    const { period, treemapOptions, pieOptions, stickersColumnDefs, SKUsColumnDefs, scanAcivity } = this.state;
    return (
      <div className="content">
        {this.renderAddStickerModal()}
        <Grid>
          <Row>
            <Col lg={4} sm={6}>
              <Card
                title="Actions"
                content={
                  <Button onClick={()=> this.toggleModal()}>Add a new Sticker</Button>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
            </Col>
            <Col lg={4} sm={6}>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <StatsCard
                bigIcon={<i className="fa fa-microchip text-info" />}
                statsText="Stickers"
                statsValue={this.props.data.tags.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4}>
              <StatsCard
                bigIcon={<i className="pe-7s-link text-success" />}
                statsText="Linked"
                statsValue={this.props.data.linked.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="% Linked Scanned"
                statsValue={`${this.getPercentLinkedScanned()}%`}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card
                title="Most Scanned SKUs"
                category="All period"
                statsIcon="fa fa-clock-o"
                stats="All Stickers"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={pieOptions}
                  />
                }
              />
            </Col>
            <Col lg={6} sm={6}>
             <Card
                statsIcon="fa fa-clock-o"
                title="Total scanned by type"
                category="Actual State"
                stats="All Stickers"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={treemapOptions}
                  />
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Scan Activity"
                category={`For ${period.chart} period`}
                stats={this.renderPeriodButtons('chart')}
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                    style={{  height: '345px', width: '100%' }}
                  >
                    <ChartistGraph  style={{  height: '100%', width: '100%' }} data={this.getScanAcivity()} type="Line" />
                  </div>
                }
              />
            </Col>
            <Col lg={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Map"
                category={`For ${period.map} period`}
                stats={this.renderPeriodButtons('map')}
                content={<ScansMap data={this.props.data.scansByPeriod} period={period.map} lat={25.2048} lng={55.2708} />}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Stickers"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridReady}
                      pagination={true}
                      rowSelection='single'
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={stickersColumnDefs}
                      rowData={this.props.data.tags} 
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    addSticker: (sticker) => {
      dispatch(addStickerThunk(sticker));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stickers);
