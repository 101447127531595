import firebase from '../Firebase';
const async = require('async');

const refTags = firebase.firestore().collection('Tags');
const refScans = firebase.firestore().collection('Scans');
const refSKUs = firebase.firestore().collection('SKUs');
const refUsers = firebase.firestore().collection('Users');
const refEntities = firebase.firestore().collection('Entities');
const refEvents = firebase.firestore().collection('Events');
/**
* ACTION TYPES
*/
//const ADD_SKU = 'ADD_SKU';
const GET_ALL_TAGS = 'GET_ALL_TAGS';
const GET_ALL_SCANS = 'GET_ALL_SCANS';
const GET_ALL_SKUs = 'GET_ALL_SKUs';
const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
const GET_ALL_USERS = 'GET_ALL_USERS';
const SET_USER = 'SET_USER';
const LOGOUT = 'LOGOUT';

/**
* ACTION CREATORS
*/
//export const addSKU = (SKUData) => ({ type: ADD_SKU, data: SKU });
export const getAllTags = (tags) => ({ type: GET_ALL_TAGS, data: tags });
export const getAllEvents = (events) => ({ type: GET_ALL_EVENTS, data: events });
export const getAllScans = (scans) => ({ type: GET_ALL_SCANS, data: scans });
export const getAllSKUs = (SKUs) => ({ type: GET_ALL_SKUs, data: SKUs });
export const getAllUsers = (users) => ({ type: GET_ALL_USERS, data: users });
export const setUser = (user) => ({ type: SET_USER, data: user });
export const logout = () => ({ type: LOGOUT, data: {} });
/**
* THUNKS
*/

export function logoutThunk() {
 return dispatch => {
    firebase.auth().signOut().then(() =>{
    	dispatch(logout());
    }).catch((error) => {
    });
	}
}

export function loginThunk(fullNumber, captcha, cb) {
 return dispatch => {
	firebase.auth().signInWithPhoneNumber(fullNumber, captcha)
    .then(confirmResult =>  { 
      cb(true, confirmResult);
    })
    .catch((error) => {
        if(error) {
          cb(false, null);
        }
    });
	}
}

export function watchIsLoginThunk(cb) {
 return dispatch => {
	firebase.auth().onAuthStateChanged((userAuth) => {
    if (userAuth) {
      refUsers.doc(userAuth.uid).get().then((doc)=>{
        const user = doc.data();
        if(!user.entityId) return; //temprorary, we don't redirect users without entityId
        refEntities.doc(user.entityId).get().then((doc)=>{
           const dbEntity = doc.data();
           localStorage.setItem('user', JSON.stringify(user));
          dispatch(setUser({data: user, isLogin: true, id: userAuth.uid, entity:dbEntity }));
          cb(true);
        });
      }); 
    } else {
      dispatch(setUser({data: {}, isLogin: false, id: null }));
      cb(false);
    }
  });
 }
}

export function getAllTagsThunk(entityId) {
 	return dispatch => {
	 	const tags = [];
	 	refTags.where('entityId', '==', entityId).get().then((querySnapshot)=> {
	 	  querySnapshot.forEach((doc) => {
	      if (doc.exists) {
	        const DBTag = doc.data();
          DBTag.docId = doc.id;
	        tags.push(DBTag);
	      }
	    });
	 }).then(() => dispatch(getAllTags(tags)))
 }
}


export function getAllEventsThunk(entityId) {
  return dispatch => {
    const events = [];
    refEvents.where('entityId', '==', entityId).get().then((querySnapshot)=> {
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const DBEevent = doc.data();
          events.push(DBEevent);
        }
      });
   }).then(() => dispatch(getAllEvents(events)))
 }
}

export function getAllScansThunk(entityId) {
 	return dispatch => {
	 	const scans = [];
	 	refScans.where('entityId', '==', entityId).orderBy('timestamp', 'asc').get().then((querySnapshot)=> {
	 	  querySnapshot.forEach((doc) => {
	      if (doc.exists) {
	        const DBScan= doc.data();
          DBScan.docId = doc.id;
	        scans.push(DBScan);
	      }
	    });
	 }).then(() => dispatch(getAllScans(scans)))
 }
}

export function watchAllScansThunk(entityId) {
  return dispatch => {
  	const scans = [];
    refScans.where('entityId', '==', entityId).orderBy('timestamp', 'asc').onSnapshot((querySnapshot) =>
    { 
      querySnapshot.docChanges().forEach((change) => {
        if(change.type=="added"){//first time it will be triggered
        	const DBScan = change.doc.data();
        	 scans.push(DBScan);
        }
      });
      dispatch(getAllScans(scans))
    });
  }
};

export function watchAllSKUsThunk(entityId) {
  return dispatch => {
  	const SKUs = [];
    refSKUs.where('entityId', '==', entityId).onSnapshot((querySnapshot) =>
    { 
      querySnapshot.docChanges().forEach((change) => {
        if(change.type=="added"){//first time it will be triggered
        	const DBSKU = change.doc.data();
        	 SKUs.push(DBSKU);
        }
      });
      dispatch(getAllSKUs(SKUs))
    });
  }
};

export function getAllSKUsThunk(entityId) {
 	return dispatch => {
	 	const SKUs = [];
	 	refSKUs.where('entityId', '==', entityId).get().then((querySnapshot)=> {
	 	  querySnapshot.forEach((doc) => {
	      if (doc.exists) {
	        const DBSKU= doc.data();
          DBSKU.docId = doc.id;
	        SKUs.push(DBSKU);
	      }
	    });
	 }).then(() => dispatch(getAllSKUs(SKUs)))
 }
}

// TODO optimize this
export function getAllUsersThunk(users) {
  return dispatch => {
    const usersById = {};
    let i = 0;
    users.forEach((uid)=> {
      refUsers.doc(uid).get().then((doc)=>{
        if (doc.exists) {
          const DBUser = doc.data();
          usersById[uid] = DBUser;
          i++;
          if(i === users.length){
            dispatch(getAllUsers(usersById));
          }
        }
     });
    }); 
  }
}

export function addSKUThunk(SKUData) {
  return dispatch => {
    const entityId = JSON.parse(localStorage.getItem('user')).entityId;
    SKUData.entityId = entityId;
    refSKUs.add(SKUData).then((doc)=>{
      dispatch(getAllSKUsThunk(entityId));
    });
  }
}

export function addStickerThunk(sticker) {
  return dispatch => {
    const entityId = JSON.parse(localStorage.getItem('user')).entityId;
    sticker.entityId = entityId;
    refTags.add(sticker).then((doc)=>{
      dispatch(getAllTagsThunk(entityId));
    });
  }
}

export function updateSKUThunk(SKU) {
  return dispatch => {
    const entityId = JSON.parse(localStorage.getItem('user')).entityId;
    refSKUs.doc(SKU.docId).set(SKU, {merge: true}).then((doc)=>{
      dispatch(getAllSKUsThunk(entityId));
    });
  }
}

export function updateStickerThunk(sticker) {
  return dispatch => {
    const entityId = JSON.parse(localStorage.getItem('user')).entityId;
    refTags.doc(sticker.docId).set(sticker, {merge: true}).then((doc)=>{
      dispatch(getAllTagsThunk(entityId));
    });
  }
}
