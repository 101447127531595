/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, ButtonGroup, Button } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { ScansMap } from "components/Maps/ScansMap.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import addTreemapModule from 'highcharts/modules/treemap';
import  Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getAllUsersThunk } from '../reducers/actions';
import config from "../config";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
addTreemapModule(Highcharts);
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityPieOptions: {},
      OSPieOptions: {},
      accountTypePieOptions: {},
      usersByCityPieOptions: {},
    }
    //this.updateUsersColumnDefs = this.updateUsersColumnDefs.bind(this);
  }

  countUnique(iterable) {
    return new Set(iterable).size;
  }

  componentDidMount() {
    this.updateActivityPieOptions(this.props);
    this.updateOSPieOptions(this.props);
    this.updateAccountTypePieOptions(this.props);
    this.updateusersByCityOptions(this.props);
    this.updateUsersColumnDefs(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps.data) != JSON.stringify(this.props.data)) {
      this.updateActivityPieOptions(nextProps);
      this.updateOSPieOptions(nextProps);
      this.updateAccountTypePieOptions(nextProps);
      this.updateusersByCityOptions(nextProps);
      this.updateUsersColumnDefs(nextProps);
    }
  }

  updateUsersColumnDefs(nextProps) {
    this.setState({
      usersColumnDefs: [{
        headerName: "user Id",
        sortable: true,
        filter: true,
        valueGetter: (params) => {
          return params.data;
        }
      },
      {
        headerName: "Email", 
        sortable: true,
        valueGetter: (params) => {
          const user = nextProps.data.usersById[params.data];
          return (user && user.email) ? user.email : '-';
        },
      },
      {
        headerName: "Phone", 
        sortable: true,
        valueGetter: (params) => {
          const user = nextProps.data.usersById[params.data];
          return (user && user.phone) ? user.phone : '-';
        },
      },
      {
        headerName: "Name", 
        sortable: true,
        valueGetter: (params) => {
          const user = nextProps.data.usersById[params.data];
          return (user && user.name) ? user.name : '-';
        },
      },
      {
        headerName: "Nb scans", 
        sortable: true,
        valueGetter: (params) => {
          return nextProps.data.scansByUserId[params.data] ? nextProps.data.scansByUserId[params.data].length : 0;
        },
      }],
    });
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  updateAccountTypePieOptions(nextProps) {
    const data = [];
    Object.keys(nextProps.data.accountTypeByUserNb).forEach((type)=>{
      data.push({name: type, y:nextProps.data.accountTypeByUserNb[type] });
    });
    this.setState({
      accountTypePieOptions: {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 230,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            colors: config.COLORS1,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'TYPE',
          colorByPoint: true,
          data,
        }]
      }
    });
  }

  updateActivityPieOptions(nextProps) {
    const data = [];
    Object.keys(nextProps.data.scansByUserId).forEach((id)=>{
      const user = nextProps.data.usersById[id];
      const name = user ? (user.email ? user.email : (user.name ? user.name : 'UNKNOWN')) : 'loading';
     data.push({name, y:nextProps.data.scansByUserId[id] });
    });
    this.setState({
      activityPieOptions: {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 230,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          name: 'SCANS',
          colorByPoint: true,
          data,
        }]
      }
    });
  }

  updateOSPieOptions(nextProps) {
    const data = [{name: 'ANDROID', y: nextProps.data.OSByUserNb.ANDROID}, {name: 'IOS', y: nextProps.data.OSByUserNb.IOS}];
    this.setState({
      OSPieOptions: {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 230,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            colors: config.COLORS,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          innerSize: '50%',
          name: 'NUMBER',
          colorByPoint: true,
          data,
        }]
      }
    });
  }

  updateusersByCityOptions(nextProps) {
    const data = [];
    const usersByCity = nextProps.data.usersByCity;
    Object.keys(usersByCity).forEach((key)=> {
      data.push({ name:key.toUpperCase(), y:usersByCity[key]});
    });
    this.setState({
      usersByCityPieOptions: {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 230,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            colors: config.COLORS3,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: [{
          innerSize: '50%',
          name: 'NUMBER',
          colorByPoint: true,
          data,
        }]
      }
    });
  }

  onBtnExport() {
    const params = {suppressQuotes: false, columnSeparator: ',', fileName: 'Users.csv' };
    this.gridApi.exportDataAsCsv(params);
  }

  render() {
    const { activityPieOptions, OSPieOptions, accountTypePieOptions, usersByCityPieOptions, usersColumnDefs, data } = this.state;
    return (
      <div className="content">
        <Grid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-users text-info" />}
                statsText="Users"
                statsValue={this.props.data.users.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-success" />}
                statsText="Scans"
                statsValue={this.props.data.scans.length}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-signal text-info" />}
                statsText="Avg scan per user"
                statsValue={this.props.data.users.length > 0 ? (this.props.data.scans.length/this.props.data.users.length).toFixed(0) : 0}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Since the launch"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card
                title="Most Active Users"
                category="All period"
                statsIcon="fa fa-clock-o"
                stats="All users"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={activityPieOptions}
                  />
                }
              />
            </Col>
            <Col lg={6}>
             <Card
                statsIcon="fa fa-clock-o"
                title="Users by OS"
                category="All period"
                stats="All Stickers"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={OSPieOptions}
                  />
                }
              />
            </Col>
           </Row>
          <Row>
          <Col lg={6}>
            <Card
                statsIcon="fa fa-clock-o"
                title="Users by Account Type"
                category="All period"
                stats="All Stickers"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={accountTypePieOptions}
                  />
                }
              />
            </Col>
          <Col lg={6}>
            <Card
                statsIcon="fa fa-clock-o"
                title="Users by city"
                category="All period"
                stats="All Stickers"
                content={
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={usersByCityPieOptions}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Users"
                category="All period"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="ag-theme-balham" style={{  height: '500px', width: '100%' }}>
                    <Button style={{  marginBottom: 10 }} bsStyle="primary" onClick={()=> this.onBtnExport()}>CSV</Button>
                    <AgGridReact
                      onGridReady={this.onGridReady}
                      pagination={true}
                      rowSelection='single'
                      paginationAutoPageSize={true}
                      sizeColumnsToFit={true}
                      columnDefs={usersColumnDefs}
                      rowData={this.props.data.users} 
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.globalReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllUsers: (users) => {
      dispatch(getAllUsersThunk(users));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
