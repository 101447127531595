/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Scans from "views/Scans.jsx";
import Maps from "views/Maps.jsx";
import SKUs from "views/SKUs.jsx";
import SKU from "views/SKU.jsx";
import Stickers from "views/Stickers.jsx";
import Users from "views/Users.jsx";
import Marketing from "views/Marketing.jsx";

const dashboardRoutes = [
  {
    path: "/SKU/:SKUCode",
    name: "SKU",
    icon: "pe-7s-news-paper",
    component: SKU,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/stickers",
    name: "Stickers",
    icon: "pe-7s-news-paper",
    component: Stickers,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "pe-7s-user",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/marketing",
    name: "Marketing",
    icon: "pe-7s-speaker",
    component: Marketing,
    layout: "/admin"
  },
  {
    path: "/scans",
    name: "Scans",
    icon: "pe-7s-signal",
    component: Scans,
    layout: "/admin"
  },
  {
    path: "/SKUs",
    name: "SKUs",
    icon: "pe-7s-ticket",
    component: SKUs,
    layout: "/admin"
  },
];

export default dashboardRoutes;
