/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const config = {
  COLORS: ["#7f8fa6", "#273c75", "#00a8ff", "#9c88ff", "#fbc531", "#4cd137", "#2f3640"],
  COLORS1: ["#ef5777", "#575fcf", "#ff3f34", "#0be881", "#ffdd59", "#ff5e57", "#ff5e57"],
  COLORS3: ["#7d5fff", "#18dcff", "#7158e2", "#32ff7e", "#17c0eb", "#c56cf0", "#ff5e57"],
};

export default config;
