/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import pin from "assets/img/pin.png";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

export class ScansMap extends Component {

  componentDidMount(){
    this.init = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { period, data, lat, lng } = this.props;
    if(!this.arraysEqual(this.props.data[this.props.period], nextProps.data[nextProps.period])) {
      return true;
    }
    return false;
  }

  arraysEqual(a1,a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1)==JSON.stringify(a2);
  }

  render() {
    const { period, data, lat, lng } = this.props;
    const CustomMap = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat, lng }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true
        }}
      > 
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
      >   
        { 
          data[period].map((scan, key)=>{
            if(scan.coordinates)
            return <Marker icon={pin} key={key} position={{ lat: scan.coordinates.latitude, lng: scan.coordinates.longitude }} />;
          })
          
        }
        </MarkerClusterer>
      </GoogleMap>
    ))
  );
  return (
    <CustomMap
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6m844CNDwn8JNb-WBZmnCaN9t0rFE6I8"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />);
  }
}

export default ScansMap;
